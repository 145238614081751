<template>
    <div class="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/3 px-3 py-3">
        <a 
            :disabled="!review_completed"
            @click.prevent="navigateToReview"
            :class="review_completed? 'cursor-pointer': ''"
        >
            <div class="bg-scorelitgray overflow-hidden shadow rounded-lg group">
                <div class="relative overflow-hidden w-full h-48 bg-gradient-to-b from-scorelitorange to-scorelitred rounded-t-lg">
                    <img v-if="thumbnail" class="w-full h-48 object-cover transition duration-300 transform group-hover:-translate-y-1 group-hover:scale-105" :src="thumbnail" alt="review avatar">
                    <div v-if="!review_completed" class="centered h-12 w-12 numberCircle p-2">
                        <img class="h-6 w-6 centered" src="@/assets/icons/hourglass-light.svg">
                    </div>
                </div>
                <div class="px-4 py-4 h-30 sm:px-6">
                    <div class="flex">
                        <div class="flex-1 pl-2 py-2">
                            <span class="text-white block font-bold">{{ review.match.video.title }}</span>
                            <span class="text-xs text-gray-500">{{ longDateToHumanReadable(review.created_at, false) }}</span>
                        </div>
                        <div class="flex-initial pr-2 py-2">
                            <div v-if="review_completed" class="flex relative z-0 overflow-hidden">                    
                                <img v-if="coach_thumbnail" class="border-2 border-gray-900 relative z-10 inline-block h-10 w-10 rounded-full text-white shadow-solid" :src="coach_thumbnail" alt="coach-picture">
                                <div class="border-2 border-gray-900 relative z-0  inline-block h-10 w-10 ml-3 numberCircle flex justify-center items-center" :class="coach_thumbnail ? '-ml-2': ''">
                                    <span class="text-white text-sm font-medium">{{ score }}</span>
                                </div>
                            </div>
                            <div v-else class="flex relative z-0 overflow-hidden">
                                <!-- Use first element to push the second element to the right -->
                                <div class="invisible border-2 border-gray-900 relative inline-block h-10 w-10 ml-3 numberCircle flex justify-center items-center">
                                    <span class="text-white text-sm font-medium"></span>
                                </div>
                                <img v-if="coach_thumbnail" class="border-2 border-gray-900 relative z-0 -ml-2 inline-block h-10 w-10 rounded-full text-white shadow-solid" :src="coach_thumbnail" alt="coach-picture">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
import { longDateToHumanReadable } from "@/helpers/DateHelper";

export default {
    props: {
        review: Object,
    },
    computed:{
        thumbnail: function (){
            return this.review.match.video.thumbnail;
        },
        coach_thumbnail: function(){
            return this.review.coach?.thumbnail;
        },
        score: function(){
            if (!this.review || !this.review.spec){
                return "-"
            }
            return Math.round(this.review.spec.average);
        },
        review_completed: function (){
            return this.review.reviewed_at !== null;
        }
    },
    methods: {
        longDateToHumanReadable,
        navigateToReview(){
            if (!this.review_completed){
                return;
            }
            this.$router.push({name: 'review.show', params: {id: this.review.id}});
        }
    },
}
</script>

<style scoped>
    /* credit: https://stackoverflow.com/questions/4861224/how-to-use-css-to-surround-a-number-with-a-circle */
    .numberCircle {
        border-radius: 50%;
        background: linear-gradient(to right, #F7941D 0, #EF4136 100%);
    }
    .centered {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
</style>