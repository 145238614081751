import axios from 'axios';

const index_review_path = "v1/trainee/review"; 
const get_review_path = "v1/trainee/review/"; // + id 

function indexReview()
{
    return new Promise((resolve, reject) =>{
        axios.get(index_review_path)
            .then((response)=>{
                resolve(response.data);
            })
            .catch((err) => {
                reject(err.response.data);
            });
    });
}

function getReview(id)
{
    const url = get_review_path + id;

    return new Promise((resolve, reject) =>{
        axios.get(url)
            .then((response)=>{
                resolve(response.data.reviews);
            })
            .catch((err) => {
                reject({...err.response.data, status: err.response.status});
            });
    });
}

export { indexReview, getReview };