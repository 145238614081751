// import translation function
import { getGlobalModifiedT } from "@/i18n";
const t = getGlobalModifiedT('helpers.DateHelper.');

function dateToHumanReadable(time_stamp){
    const date = new Date(time_stamp);
    const now = new Date();

    let str = date.getDate() + "-" + (date.getMonth() + 1);

    if(date.getFullYear() !== now.getFullYear()){
        str += "-" + date.getFullYear();
    }

    str += " " + date.getHours() + ":" + date.getMinutes();

    return str;
}

function longDateToHumanReadable(time_stamp, trim_year = true){
    const date = new Date(time_stamp);
    const now = new Date();

    let str = date.getDate() + " " + monthToString(date.getMonth());

    if(date.getFullYear() !== now.getFullYear() || !trim_year){
        str += " " + date.getFullYear();
    }

    return str;
}

function monthToString(n)
{
    switch(n){
        case 0:
            return t('january');
        case 1:
            return t('february');
        case 2:
            return t('march');
        case 3:
            return t('april');
        case 4:
            return t('may');
        case 5:
            return t('june');
        case 6:
            return t('july');
        case 7:
            return t('august');
        case 8:
            return t('september');
        case 9:
            return t('october');
        case 10:
            return t('november');
        case 11:
            return t('december');

        default:
            return null;
    }
}

export { dateToHumanReadable, longDateToHumanReadable };