<template>
    <HideWhileSpinnerActive>
        <div v-if="reviews.length !== 0">
            <div class="px-2 pt-10">
                <h1 class="text-white font-semibold text-2xl px-2 whitespace-pre-line" v-text="t('your_reviews')" />
            </div>
            <div class="flex flex-wrap">
                <ReviewItem v-for="review in reviews" :key="review.id" :review="review" />
            </div>
        </div>
        <template v-else>
            <!-- Empty state -->
            <div class="md:max-w-xl m-auto">
                <img class="object-scale-down h-64 w-full" src="@/assets/images/Screenshot_from_2020-10-13_14-30-38.png" alt="">
                <div class="text-white text-center">
                    <h3 class="heading-1 whitespace-pre-line" v-text="t('no_reviews')" />
                    <p class="text-sm px-6 py-6 md:px-24">{{ t('select_expert_choose_match_and_receive_review') }}</p>
                </div>
                <div class="mt-6">
                    <router-link :to="{ name: 'dashboard' }">
                        <span class="block w-full rounded-md shadow-sm">
                            <button type="button" class="button w-full">
                                {{ t('choose_expert') }}
                            </button>
                        </span>
                    </router-link>
                </div>
            </div>
        </template>
    </HideWhileSpinnerActive>
</template>

<script>
import ReviewItem from "@/components/review/IndexItem";
import { indexReview } from "@/helpers/api/ReviewHelper";
import HideWhileSpinnerActive from "@/wrappers/HideWhileSpinnerActive";
import { startSpinner, stopSpinner } from "@/helpers/storage/loadingSpinnerHelper";
import useI18n from "@/modules/globalI18n";

export default {
    name: "ReviewIndex",
    setup() {
        return useI18n({
            viewPrefix: "ReviewIndex"
        });
    },
    components: { ReviewItem, HideWhileSpinnerActive },
    created(){
        startSpinner()
        indexReview()
            .then(({ reviews }) => {
                this.reviews = reviews;

                const sortByTimeDesc = (a, b) => (new Date(b.created_at)).getTime() - (new Date(a.created_at)).getTime();
                this.reviews.sort(sortByTimeDesc);
            })
            .finally(()=> stopSpinner());
    },
    data(){
        return {
            reviews: [],
        }
    },
}
</script>