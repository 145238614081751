<template>
    <div v-if="show" class="h-full">
        <slot />
    </div>
</template>

<script>
import { isLoading } from "@/helpers/storage/loadingSpinnerHelper";

export default {
    name: "HideWhileSpinnerActive",
    computed: {
        show: function () {
            return !isLoading();
        }
    }
}
</script>